const Custom404 = () => {
	return (
		<div className="not-found">
			<div className="container grid place-content-center h-96">
				<p>404 - No encontrado</p>
			</div>
		</div>
	)
}

export default Custom404
